<template>
  <v-card
    class="d-flex flex-column item-card"
    elevation="0"
    @click="redirectToItemPage"
  >
    <section v-if="name === 'field'">
      <gmap
        data-id="enabled-map"
        v-if="item.enabled"
        class="item-card__map"
        disable-user-interaction
        :plot="item"
      />
      <div v-else class="map__disabled">
        <p>{{ $t('he_disabled') }}</p>
        <gmap class="item-card__map" disable-user-interaction :plot="item" />
      </div>
    </section>
    <section class="d-flex flex-column item-card__content">
      <section class="d-flex justify-space-between align-start">
        <h1>{{ title }}</h1>
        <v-menu offset-x>
          <template #activator="{ on }">
            <v-btn icon height="24px" width="24px" v-on="on">
              <font-awesome-icon color="#5B6459" icon="ellipsis-v" />
            </v-btn>
          </template>
          <v-card>
            <v-card-actions
              class="d-flex flex-column align-start content__actions pa-0"
            >
              <v-btn
                class="actions__edit pl-4 pr-6 py-3 rounded-0"
                plain
                text
                @click="redirectToItemPage"
              >
                {{ $t('editar') }}
              </v-btn>
              <v-btn
                class="actions__exclude pl-4 pr-6 py-3 ml-0 rounded-0"
                plain
                text
                @click="openDeleteModal"
              >
                {{ $t('excluir') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </section>
      <section class="d-flex flex-column content__infos">
        <div v-if="name === 'field'" class="d-flex align-center info__row">
          <font-awesome-icon color="#5B6459" icon="seedling" />
          <span
            >{{ sowingByField.length }} {{ $t('Home.item_list.sowings') }}</span
          >
        </div>
        <div v-if="name === 'sowing'" class="d-flex align-center info__row">
          <font-awesome-icon color="#5B6459" icon="seedling" />
          <span>{{ item.cultivar }}</span>
        </div>
        <div v-if="name === 'sowing'" class="d-flex align-center info__row">
          <font-awesome-icon color="#5B6459" icon="calendar" />
          <span>{{ item.data_semeadura.formatDate() }}</span>
        </div>
        <div v-if="name === 'sowing'" class="d-flex align-center info__row">
          <font-awesome-icon color="#5B6459" icon="vector-square" />
          <span>{{ item.talhao }}</span>
        </div>
      </section>
    </section>
    <df-delete-modal
      v-if="isDeleteModalOpen"
      :title="deleteMessage"
      @action-click="deleteItem"
      @close="isDeleteModalOpen = false"
    />
  </v-card>
</template>

<script>
import DfDeleteModal from '@/lib/Modal/DfDeleteModal.vue'
import Gmap from '@/modules/plot/components/Maps/Gmap.vue'
import { logEvent, events } from '@/services/analytics'
import { getCropNameById } from '@/utils/crops'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ItemCard',

  components: {
    DfDeleteModal,
    Gmap,
  },

  props: {
    item: {
      required: true,
      type: Object,
    },
    name: {
      default: 'field',
      type: String,
    },
  },

  data() {
    return {
      isDeleteModalOpen: false,
    }
  },

  computed: {
    ...mapGetters('farms', ['currentFarmId']),
    ...mapGetters('semeaduras', ['allSowings']),
    deleteMessage() {
      const itemMessages = {
        field: this.$t('Modal.delete.field_delete_confirmation'),
        sowing: this.$t('Modal.delete.sowing_delete_confirmation'),
      }
      return itemMessages[this.name]
    },
    sowingByField() {
      const sowings = this.allSowings.filter(
        (sowing) => sowing.talhao_id == this.item.id && !sowing.colheita
      )
      return sowings
    },
    title() {
      if (this.name === 'field') {
        return this.item.nome
      }
      return this.$t(getCropNameById(this.item.crop_id))
    },
  },

  methods: {
    ...mapActions('plot', ['deletePlot']),
    ...mapActions('semeaduras', ['loadAllSowings']),
    async deleteItem() {
      if (this.name === 'field') {
        this.deletePlot(this.item.id)
        logEvent(events.homeModule['clickedButtonDeleteField'])
      } else {
        this.deleteSowing()
        logEvent(events.homeModule['clickedButtonDeleteSowing'])
      }
      this.isDeleteModalOpen = false
    },
    async deleteSowing() {
      await this.$api.semeaduras.delete(this.item.id)
      this.loadAllSowings(['status', 'colheita'])
    },
    openDeleteModal() {
      logEvent(events.homeModule.clickedButtonDeleteField)
      this.isDeleteModalOpen = true
    },
    redirectToItemPage() {
      const redirectParams = {
        field: {
          event: 'clickedButtonEditField',
          router: {
            path: '/plot',
            query: {
              plot_id: this.item.id,
              fazenda_id: this.currentFarmId,
            },
          },
        },
        sowing: {
          event: 'clickedButtonSeeSowing',
          router: {
            path: '/execucao',
            query: {
              semeadura_id: this.item.id,
              fazenda_id: this.currentFarmId,
            },
          },
        },
      }
      logEvent(events.homeModule[redirectParams[this.name].event])
      this.$router.push({
        path: redirectParams[this.name].router.path,
        query: redirectParams[this.name].router.query,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.item-card {
  height: 100%;
  width: 100%;
  min-width: 235px;
  max-width: 235px;
  border-top: none;
  border-radius: 4px;
  background: #ffffff;

  @include d(m) {
    max-width: 100%;
  }
  .map__disabled {
    position: relative;

    p {
      position: absolute;
      color: #071505;
      font-family: Rubik;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 1;
      text-transform: uppercase;
    }
    .vue-map-container {
      background-color: #e6e9e6;
      opacity: 0.4;
    }
  }
  .item-card__map {
    height: 123px !important;

    @include d(m) {
      width: 100%;
    }
  }
  .item-card__content {
    flex: 1;
    border: 1px solid #e6e9e6;
    padding: 16px;
    gap: 16px;

    .content__actions {
      border: 1px solid #e6e9e6;
      background-color: #ffffff;
    }
    h1 {
      color: #071505;
      font-family: 'Rubik';
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      text-transform: capitalize;
    }
    .content__infos {
      gap: 8px;

      .info__row {
        gap: 16px;

        svg {
          height: 16px;
          width: 16px;
        }

        span {
          color: #5b6459;
          font-family: 'Rubik';
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          text-transform: capitalize;
        }
      }
    }
  }
}
::v-deep .vue-map {
  border-radius: 4px 4px 0px 0px;
}
::v-deep .actions__edit .v-btn__content,
::v-deep .actions__exclude .v-btn__content {
  background-color: #ffffff;
  color: #071505;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  text-transform: capitalize;
  opacity: 1 !important;
}
::v-deep .actions__edit .v-btn__content {
  color: #5b6459;
}
::v-deep .actions__exclude .v-btn__content {
  color: #f23528;
}
::v-deep .vue-map > :nth-child(2) {
  display: none;
}
</style>
